import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Game from './components/Game';
import './App.css'; // Ensure the correct path to your CSS file

const TRACKING_ID = "G-EVPXS0J30N"; // Your Google Analytics tracking ID

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize(TRACKING_ID);

    // Track the initial page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="app">
      <header className="header">
        <h1>Bravo Connections</h1>
      </header>
      <p className="p">Create four groups of four!</p>
      <Game />
    </div>
  );
}

export default App;