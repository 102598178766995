import React, { useState } from 'react';
import Board from './Board';
import andyCohenImage from '../assets/andy_cohen.png'; // Ensure the path is correct
import './App.css';

const initialTiles = [
  'Adrienne Maloof', 'Camille Grammer', 'Kyle Richards', 'Taylor Armstrong',
  'Paige DeSorbo', 'Dorit Kemsley', 'Chanel Ayan', 'Jenna Lyons',
  'Brandi Glanville', 'Marlo Hampton', 'Sutton Stracke', 'Dolores Catania',
  'Hannah Burner', 'Lisa Vanderpump', 'Caroline Manzo', 'Kim Richards'
];

const correctGroups = [
  {
    members: ['Adrienne Maloof', 'Camille Grammer', 'Taylor Armstrong', 'Kyle Richards'],
    reason: 'The Bravo Divorce Curse:'
  },
  {
    members: ['Paige DeSorbo', 'Dorit Kemsley', 'Chanel Ayan', 'Jenna Lyons'],
    reason: 'Fashion Icons:'
  },
  {
    members: ['Brandi Glanville', 'Marlo Hampton', 'Sutton Stracke', 'Dolores Catania'],
    reason: '"Friend of..." to Housewife'
  },
  {
    members: ['Hannah Burner', 'Lisa Vanderpump', 'Caroline Manzo', 'Kim Richards'],
    reason: 'Dramatic Show Exits:'
  }
];

const Game = () => {
  const [tiles, setTiles] = useState([...initialTiles].sort(() => Math.random() - 0.5));
  const [selectedTiles, setSelectedTiles] = useState([]);
  const [mistakes, setMistakes] = useState(0);
  const [previousSubmissions, setPreviousSubmissions] = useState([]);
  const [completedGroups, setCompletedGroups] = useState([]);
  const [hints, setHints] = useState([]);
  const [hintIndex, setHintIndex] = useState(0);
  const [gameStatus, setGameStatus] = useState('playing'); // 'playing', 'won', 'lost'

  const shuffleTiles = () => {
    setTiles([...tiles].sort(() => Math.random() - 0.5));
  };

  const handleTileClick = (tile) => {
    if (gameStatus !== 'playing') return;

    setSelectedTiles(prev => {
      if (prev.includes(tile)) {
        return prev.filter(t => t !== tile);
      } else if (prev.length < 4) {
        return [...prev, tile];
      }
      return prev;
    });
  };

  const handleSubmit = () => {
    if (selectedTiles.length !== 4 || gameStatus !== 'playing') return;

    const currentSelection = selectedTiles.sort();
    const currentSelectionStr = currentSelection.join(',');

    if (!previousSubmissions.includes(currentSelectionStr)) {
      setPreviousSubmissions([...previousSubmissions, currentSelectionStr]);

      const foundGroup = correctGroups.find(group => group.members.sort().join(',') === currentSelectionStr);
      if (foundGroup) {
        setCompletedGroups([...completedGroups, foundGroup]);
        setTiles(tiles.filter(tile => !currentSelection.includes(tile))); // Remove correct tiles

        if (completedGroups.length + 1 === correctGroups.length) {
          setGameStatus('won');
        }
      } else {
        setMistakes(mistakes + 1);
        if (mistakes + 1 >= 4) {
          setGameStatus('lost');
        }
      }
    } else {
      alert("You have already submitted this answer!");
    }
    setSelectedTiles([]);
  };

  const generateHint = () => {
    if (hintIndex < 2) {
      const hintIndices = [];
      while (hintIndices.length < 2) {
        const index = Math.floor(Math.random() * correctGroups.length);
        if (!hintIndices.includes(index)) {
          hintIndices.push(index);
        }
      }
      const newHints = hintIndices.map(index => correctGroups[index].reason);
      setHints(newHints);
      setHintIndex(hintIndex + 1);
    }
  };

  return (
    <div className="game">
      {gameStatus === 'won' && <div className="notification1">Congratulations! You have correctly grouped all the celebrities!</div>}
      {gameStatus === 'lost' && <div className="notification">You have made too many mistakes. Game Over! Here are the correct answers:</div>}

      {completedGroups.length > 0 && (
        <div className="completed-groups">
          {completedGroups.map((group, index) => (
            <div key={index} className="completed-group">
              <div className="completed-reason">{group.reason}</div>
              <div className="completed-members">
                {group.members.join(', ')}
              </div>
            </div>
          ))}
        </div>
      )}

      {gameStatus === 'lost' && (
        <div className="completed-groups">
          {correctGroups.map((group, index) => (
            <div key={index} className="completed-group">
              <div className="completed-reason">{group.reason}</div>
              <div className="completed-members">
                {group.members.join(', ')}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="board-container">
        <Board tiles={tiles} onTileClick={handleTileClick} selectedTiles={selectedTiles} />
      </div>
      
      <div className="mistakes-remaining">
        <span>Mistakes remaining: </span>
        {[...Array(4 - mistakes)].map((_, i) => (
          <img key={i} src={andyCohenImage} alt="Andy Cohen" />
        ))}
      </div>
      
      <div className="button-wrapper">
        <div className="buttons">
          <button onClick={generateHint} disabled={hintIndex >= 2}>Get Hints</button>
          <button onClick={shuffleTiles}>Shuffle</button>
          <button onClick={() => setSelectedTiles([])}>Deselect all</button>
          <button onClick={handleSubmit} disabled={selectedTiles.length === 0 || mistakes >= 4}>Submit</button>
        </div>
      </div>
      
      {hints.slice(0, hintIndex).map((hint, index) => (
        <div key={index} className="hint">
          <p>Hint {index + 1}: {hint}</p>
        </div>
      ))}
    </div>
  );
};

export default Game;